<template>
    <div class="grid grid-cols-12 gap-x-4">
        <input-text
            class="col-span-12"
            label="Name"
            v-model="value.name"
            :errors="errors.name"
        />
        <input-simple-select
            class="col-span-4"
            label="Typ"
            v-model="value.type"
            :options="$store.getters.applicationSettings.model.customerInformationAttachment.type"
            :errors="errors.type"
        />

        <input-simple-select
            class="col-span-4"
            label="Status"
            v-model="value.status"
            :options="$store.getters.applicationSettings.model.customerInformationAttachment.status"
            :errors="errors.status"
        />

        <input-simple-select
            class="col-span-4"
            label="Validität"
            v-model="value.validity"
            :options="$store.getters.applicationSettings.model.customerInformationAttachment.validity"
            :errors="errors.validity"
        />

        <input-entity
            label="Ausbildungen"
            class="col-span-12"
            v-model="value.educations"
            type="education"
            :multi="true"
            :errors="errors.educations"
        />

        <div class="col-span-12">
            <label>Datei</label>
            <div
                v-if="viewAttachment"
                class="grid grid-flow-col justify-between items-center"
            >
                <div>
                    {{ viewAttachment.name }}
                </div>
                <div>
                    <a class="underline hover:no-underline">Löschen</a>
                </div>
            </div>

            <dropzone
                v-if="!value.attachment"
                accepted-files="application/pdf,image/png,image/jpeg,image/jpg,application/vnd.openxmlformats-officedocument.wordprocessingml.document"
                :max-files="1"
                endpoint="attachments"
                :removeOnResponse="true"
                v-on:complete="complete"
            />
        </div>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import Dropzone from "@/components/Dropzone.vue";

    export default {
        components: {Dropzone},
        mixins: [value],
        data() {
            return {
                tmpAttachment: null,
            }
        },
        methods: {
            complete(data) {
                this.tmpAttachment = data;
                this.value.attachment = 'api/attachments/' + data.id;
            }
        },
        computed: {
            viewAttachment() {
                if (
                    this.value.attachment
                    && this.value.attachment.hasOwnProperty('id')
                ) {
                    return this.value.attachment;
                }
                return this.tmpAttachment;
            }
        }
    }
</script>
