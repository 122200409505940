<template>
    <div class="c-data-touch-bookingForm">
        <div class="grid grid-cols-3 gap-8">
            <div class="col-span-3">
                <div
                    v-if="value.versionStatus"
                    class="bg-warning-50 p-4 mb-4 text-warning-800 border border-warning border-solid"
                >
                    <template v-if="value.versionStatus.cancellation">Storniert durch: </template>
                    <template v-if="!value.versionStatus.cancellation">Geändert durch: </template>
                    <template>{{value.versionStatus.invoiceNumber}}</template>
                    <br>
                    Ein Speichern / Aktualisieren der Rechnung ist nicht möglich.
                </div>
            </div>
            <div class="col-span-3">
                <input-text
                    v-if="value.id"
                    label="Rechnungsnummer"
                    v-model="value.invoiceNumber"
                    :errors="errors.invoiceNumber"
                    :readonly="true"
                />

                <input-entity
                    label="Standort / Mandat"
                    v-model="value.location"
                    type="location"
                    :errors="errors.location"
                />

				<input-entity
						label="Ausbildung"
						v-model="value.education"
						type="education"
						:errors="errors.education"
				/>

                <!--<input-entity
                    label="Haupt-Kurs"
                    v-model="value.mainCourse"
                    type="course"
                    :errors="errors.mainCourse"
                />-->

                <input-entity
                    label="Kurse in Rechnung"
                    v-model="value.courses"
                    type="course"
                    :errors="errors.courses"
                    :multi="true"
                    :extend-with-collection-api="true"
                />

				<input-entity
						label="Kostenstelle (wenn FÖBG)"
						v-model="value.supportedCostCenter"
						type="supportedCostCenter"
						:errors="errors.supportedCostCenter"
				/>

				<input-entity
						label="Kontakt Kostenstelle (wenn FÖBG)"
						v-model="value.supportedCostCenterContactPerson"
						type="supportedCostCenterContactPerson"
						:errors="errors.supportedCostCenterContactPerson"
				/>

                <div class="grid grid-cols-3 gap-x-8">
                    <label>
                        Buchungs-Typ
                        <input-simple-select
                            :options="$store.getters.applicationSettings.model.touchBooking.bookingType"
                            v-model="value.bookingType"
                            :errors="errors.bookingType"
                        />
                    </label>
                    <div>
                        <label>
                            Stornierung
                            <br>
                            <input type="checkbox" v-model="value.cancellation"/>
                        </label>
                    </div>
                </div>
            </div>

            <div class="col-span-3">
                <hr class="mt-0">
            </div>

            <input-date
                label="Datum"
                v-model="value.date"
                :errors="errors.date"
            />
            <input-date
                label="Studienstart"
                v-model="value.start"
                :errors="errors.start"
            />
            <input-date
                label="Studienende"
                v-model="value.end"
                :errors="errors.end"
            />

            <div class="col-span-3">
                <hr class="mt-0">
            </div>

            <input-currency
                label="Gesamtbetrag (Vollpreis)"
                v-model="value.total"
                :errors="errors.total"
            />

            <div class="col-span-3">
                <hr class="mt-0">
            </div>

            <input-currency
                label="Vorrauszahlung (Vollpreis) / Anzahlung Raten"
                v-model="value.prepayment"
                :errors="errors.prepayment"
            />

            <input-date
                label="Vorrauszahlung fällig am"
                :errors="errors.prepaymentDate"
                v-model="value.prepaymentDate"
            />

            <input-simple-select
                label="Art der Rechnung"
                v-model="value.paymentType"
                :errors="errors.paymentType"
                :options="$store.getters.applicationSettings.model.touchBooking.paymentType"
            />

            <div class="col-span-3">
                <hr class="mt-0">
            </div>

            <input-text
                type="number"
                label="Anzahl Raten"
                v-model.number="value.rates"
                :errors="errors.rates"
            />

            <input-currency
                label="Rate (Vollpreis)"
                v-model="value.rateValue"
                :errors="errors.rateValue"
            />

            <input-date
                label="Erste Rate fällig am"
                :errors="errors.firstRateDate"
                v-model="value.firstRateDate"
            />

            <div class="col-span-3">
                <hr class="mt-0">
            </div>

            <input-text
                label="Bank"
                v-model="value.bankName"
                :errors="errors.bankName"
            />

            <input-text
                label="IBAN"
                v-model="value.bankAccountIban"
                :errors="errors.bankAccountIban"
            />

            <input-text
                label="Kontoinhaber"
                v-model="value.bankAccountName"
                :errors="errors.bankAccountName"
            />

            <div class="col-span-3">
                <hr class="mt-0">
            </div>

            <input-currency
                label="Prüfungsgebühr (Vollpreis)"
                v-model="value.examinationFee"
                :errors="errors.examinationFee"
            />

            <input-date
                label="Prüfungsgebühr fällig am"
                :errors="errors.examinationFeeDate"
                v-model="value.examinationFeeDate"
            />

            <div></div>

            <input-currency
                label="Gebühren (Vollpreis)"
                v-model="value.fee"
                :errors="errors.fee"
            />

            <input-date
                label="Gebühren fällig am"
                :errors="errors.feeDate"
                v-model="value.feeDate"
            />

            <div></div>

            <input-currency
                label="Einschreibegebühr (Vollpreis)"
                v-model="value.enrollmentFee"
                :errors="errors.enrollmentFee"
            />

            <input-date
                label="Einschreibegebühr fällig am"
                :errors="errors.enrollmentFeeDate"
                v-model="value.enrollmentFeeDate"
            />

            <div class="col-span-3">
                <hr class="mt-0">
            </div>

            <input-text
                label="Gutschrift"
                type="number"
                :step="0.01"
                v-model.number="value.creditNote"
                :errors="errors.creditNote"
            />

            <input-text
                label="Gutschrift Bezeichnung"
                v-model="value.creditNoteNote"
                :errors="errors.creditNoteNote"
            />

            <div></div>

            <input-text
                type="number"
                label="Rabatt"
                v-model.number="value.discount"
                :errors="errors.discount"
                :max="100"
                :step="0.01"
            />

            <input-text
                label="Rabatt-Bezeichnung"
                v-model="value.discountNote"
                :errors="errors.discountNote"
            />

            <div class="col-span-3">
                <hr class="mt-0">
            </div>

            <input-text
                label="Maßnahmennummer"
                v-model="value.invoiceMeasureText"
                :errors="errors.invoiceMeasureText"
            />

            <input-text
                label="Kundenummer FÖBG"
                v-model="value.supportedCustomerNumber"
            />

            <input-text
                label="Marketing-Text"
                v-model="value.marketingNote"
            />

            <div class="col-span-3">
                <input-text
                    type="textarea"
                    label="Notiz (nur für interne Zwecke)"
                    v-model="value.invoiceAdditionalText"
                />
            </div>

            <div class="col-span-3">
                <hr class="mt-0">
            </div>

            <input-date
                label="Mahnung 1"
                :errors="errors.warningStepOne"
                v-model="value.warningStepOne"
            />

            <input-date
                label="Mahnung 2"
                :errors="errors.warningStepTwo"
                v-model="value.warningStepTwo"
            />

            <input-text
                label="Mahnung 3 / Inkasso"
                :errors="errors.warningStepThree"
                v-model="value.warningStepThree"
            />
        </div>
        <div
            v-if="value.hasOwnProperty('id')"
            class="grid grid-cols-5 gap-8"
        >
            <hr class="mt-0 col-span-5">

            <div>
                <input-text
                    :readonly="true"
                    v-model.number="value.durationUnitsGroup"
                    label="Kleingruppe (UE)"
                    step="1"
                    :errors="errors.durationUnitsGroup"
                />
            </div>
            <div>
                <input-text
                    :readonly="true"
                    v-model.number="value.durationUnitsPresence"
                    label="Selbstlernzeiten (UE)"
                    step="1"
                    :errors="errors.$durationUnitsPresence"
                />
            </div>
            <div>
                <input-text
                    :readonly="true"
                    v-model.number="value.durationUnitsProject"
                    label="Projekttage (UE)"
                    step="1"
                    :errors="errors.durationUnitsProject"
                />
            </div>
            <div>
                <input-text
                    :readonly="true"
                    v-model.number="value.durationUnitsIntership"
                    label="Praktikum (Stunden)"
                    step="1"
                    :errors="errors.durationUnitsIntership"
                />
            </div>
            <div>
                <input-text
                    :readonly="true"
                    v-model.number="value.durationUnitsFinalThesis"
                    label="Abschlussarbeit (UE)"
                    step="1"
                    :errors="errors.durationUnitsFinalThesis"
                />
            </div>
        </div>

        <div class="grid grid-cols-2 gap-8">
            <label>
                Vertrags als Anhang erstellen (nur bei Änderungen od. neuer Erstellung)
                <br>
                <input type="checkbox" v-model="value.createContractAttachment"/>
            </label>
        </div>
    </div>
</template>

<script>
    import value from "../../../mixins/value";
    import InputCurrency from "../../components/form/InputCurrency";

    export default {
        components: {InputCurrency},
        mixins: [value]
    }
</script>
