<template>
    <div v-if="timetable" class="relative">
        <c-modal
            headline="Anwesenheit eintragen"
            ref="appearanceModal"
        >
            <form
                v-on:submit.prevent="submitAppearance"
                class="py-12 px-4 grid gap-4"
            >
                <appearance v-model="editedAppearance" :errors="editedAppearanceErrors"/>

                <div class="text-center">
                    <button type="submit" class="button mb-0">
                        Eintragen
                    </button>
                </div>
            </form>
        </c-modal>

        <form
            v-on:submit.prevent="searchCourse"
            v-on:reset.prevent="resetSearchCourse"
            class="grid grid-cols-[1fr_auto_auto] items-center gap-4"
        >
            <input-text
                label="Kurscode"
                v-model="courseCode"
            />
        </form>

        <table>
            <thead>
            <tr>
                <th>Kurs</th>
                <th>Datum</th>
                <th>Status</th>
                <th>Längere Abwesenheit</th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="event in events">
                <td>
                    <router-link
                        v-if="event.course"
                        :to="'/courses/' + event.course.id"
                    >
                        <strong>
                            {{ event.code }}
                        </strong>
                    </router-link>
                    <strong
                        v-else
                    >
                        {{ event.code }}
                    </strong>
                    <br>
                    <template v-if="event.type === 'booking'">Eingebuchter Kurs</template>
                    <template v-else>Gasthörer</template>
                    <template v-if="event.online"><br>Online</template>
                </td>
                <td>
                    {{ event.date|formatDate('LL') }}
                    <template v-if="event.room">
                        <br>
                        {{ event.room.name }}
                    </template>
                </td>
                <td>
                    <template v-if="appearancesMappedByEventCourseDate.hasOwnProperty(event['@id'])">
                        {{
                            appearancesMappedByEventCourseDate[event['@id']].status|dataTrans('model.appearance.status')
                        }}
                        <template v-if="appearancesMappedByEventCourseDate[event['@id']].status === 'AV'">
                            <br>
                            {{ appearancesMappedByEventCourseDate[event['@id']].delay }} Stunden
                        </template>
                        <template v-if="appearancesMappedByEventCourseDate[event['@id']].status === 'E'">
                            <br>
                            {{
                                appearancesMappedByEventCourseDate[event['@id']].reason|dataTrans('model.appearance.reason')
                            }}
                        </template>
                    </template>
                    <template v-else>
                        <a
                            v-if="eventCourseDateTouchBooking.hasOwnProperty(event.code)"
                            @click="openAppearanceModal(event)"
                            class="action --plus"
                        >
                        </a>
                    </template>
                </td>
                <td>
                    <template v-if="hasAbsence(event)">
                        {{ hasAbsence(event).status|dataTrans('model.appearance.status') }}
                    </template>
                </td>
            </tr>
            </tbody>
        </table>

        <div class="text-center">
            <a class="button" @click="download">
                Download als CSV
            </a>
        </div>
    </div>
</template>

<script>
    import moment from "moment";
    import Appearance from "../components/form/Appearance.vue";
    import util from "../../plugins/util";

    export default {
        components: {Appearance},
        props: {
            person: {}
        },
        data() {
            return {
                courseCode: null,
                editedAppearance: {},
                editedAppearanceErrors: {},
                appearances: [],
                absences: [],
                timetable: null,
            }
        },
        computed: {
            appearancesMappedByEventCourseDate: function () {
                if (!this.appearances || this.appearances.length === 0) {
                    return {};
                }
                return this.appearances.reduce((acc, cur) => {
                    if (cur.eventCourseDate && cur.eventCourseDate.hasOwnProperty('@id')) {
                        acc[cur.eventCourseDate['@id']] = cur;
                    }
                    return acc;
                }, {});
            },
            eventCourseDateTouchBooking: function () {
                return Object.keys(this.timetable.touchBookingCourseCodes).reduce((a, b) => {
                    this.timetable.touchBookingCourseCodes[b].forEach((item) => {
                        a[item] = b;
                    });
                    return a;
                }, {});
            },
            events: function () {
                let events = [...this.timetable.events];
                events = events.map((item) => Object.assign({type: 'booking'}, item));

                this.timetable.guestTouchBookingEventDates.forEach((item) => {
                    events.push(Object.assign({type: 'guest'}, item));
                });

                events = events.reduce((acc, cur) => {
                    acc[cur['@id']] = cur;
                    return acc;
                }, {});

                Object.keys(this.appearances).forEach((item) => {
                    if (
                        this.appearances[item].hasOwnProperty('eventCourseDate')
                        && this.appearances[item].eventCourseDate
                    ) {
                        if (!events.hasOwnProperty(this.appearances[item].eventCourseDate['@id'])) {
                            events[this.appearances[item].eventCourseDate['@id']] = Object.assign({type: 'guest'}, this.appearances[item].eventCourseDate);
                        }
                    }
                });

                events = Object.values(events);

                if(this.courseCode && this.courseCode.length > 0) {
                    events = events.filter((item) => {
                        return item.code.indexOf(this.courseCode) >= 0;
                    });
                }

                return events.sort(function (a, b) {
                    return new Date(a.date) - new Date(b.date);
                });
            }
        },
        mounted() {
            this.loadTimetable();
            this.loadAppearances();
            this.loadAbsences();
        },
        watch: {
            '$route': function () {
                this.loadTimetable();
                this.loadAppearances();
                this.loadAbsences();
            }
        },
        methods: {
            submitAppearance() {
                this.editedAppearanceErrors = {};
                this.$sendToApi('appearances', this.editedAppearance, (entity, status, data) => {
                    if (status < 300) {
                        this.editedAppearance = {};
                        this.$refs.appearanceModal.close();
                        this.appearances[entity.eventCourseDate['@id']] = entity;
                    } else {
                        this.editedAppearanceErrors = util.mapConstraints(data);
                    }
                });
            },
            openAppearanceModal(eventCourseDate) {
                this.editedAppearanceErrors = {};
                this.editedAppearance = {
                    touchBooking: this.eventCourseDateTouchBooking[eventCourseDate.code],
                    person: this.person['@id'],
                    eventCourseDate: eventCourseDate['@id'],
                }
                this.$refs.appearanceModal.open();
            },
            hasAbsence(eventCourseDate) {
                let absences = this.absences.filter((item) => {
                    return moment(eventCourseDate.date).isSameOrAfter(moment(item.start))
                        && moment(eventCourseDate.date).isSameOrBefore(moment(item.end).add(1, 'day'));
                });
                if (absences.length > 0) {
                    return absences[0];
                }
                return null;
            },
            async loadAbsences() {
                this.absences = await this.$getAllFromApi(
                    'absences',
                    {
                        person: this.person['@id'],
                    }
                )
            },
            async loadAppearances() {
                this.appearances = await this.$getAllFromApi(
                    'appearances',
                    {
                        person: this.person['@id'],
                        type: 'course'
                    }
                );
                console.log(this.appearances);
                this.$forceUpdate();
            },
            download() {
                let csv = this.timetable.events.map(row => [row.date, row.code, row.room ? row.room.name : '', '']);
                csv = csv.concat(this.timetable.guestTouchBookingEventDates.map(row => [row.date, row.code, row.room ? row.room.name : '', 'GH']));

                csv =  csv.sort(function (a, b) {
                    return new Date(a[0]) - new Date(b[0]);
                });

                csv = csv.map((item) => {
                    item[0] =  this.$options.filters.formatDate(item[0], 'LL');
                    return item;
                });

                csv.unshift(['Datum', 'Kurs', 'Raum', 'Gasthörer']);
                this.$downloadCsv(csv, `Stundenplan-${this.person.lastName}.csv`);
            },
            loadTimetable() {
                this.$getFromApi('timetables', this.person, (entity) => {
                    this.timetable = entity;
                });
            }
        }
    }
</script>
